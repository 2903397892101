.invoice-filter-row {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 1rem 0;
}

.invoice-filter-row .ui.selection.dropdown {
    min-width: 10rem;
}

.invoice-filter-row .ui.icon.input {
    min-width: 14rem;
}

.invoice-filter-row .filter-input {
    width: 10rem;
}

.date-range-picker div {
    width: 10rem;
}
