/* .active {
  color: #fbbd08;
} */

.step,
.step:hover {
  text-align: center;
  padding: 10px;
  color: #969ba5;
  font-weight: bold;
}

.step-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
