.details-widget.ui.card > .details-widget-header.content {
  background-color: #f2f2f2;
  font-size: 13px;
}

.details-widget .ui.list > .item .header,
.details-widget .ui.list > .item .description {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-widget .ui.list > .item .description {
  color: #969ba5;
}
