// Moxit Color system
$white: #fff !default;
$almostWhite: #ebeff4 !default;
$darkest-gray: #111 !default;
$dark-gray: #333 !default;
$gray: rgb(119, 119, 119) !default;
$light-gray: rgb(247, 247, 247) !default;
$lighter-gray: rgba(0, 0, 0, 0.05) !default;
$black: rgb(0, 0, 0) !default;
$blue: rgb(36, 111, 169) !default;
$dark-blue: #28325a !default;
$yellow: #ffbd33 !default;
$red: #f9461c !default;
$green: #00a91c !default;
$green-bg: rgb(225, 245, 234, 100) !default;

$primary: #28325a !default;
$secondary: #f4f5f7 !default;

$bgHighlight: rgba(252, 186, 3, 0.8);
$bgHighlightHover: rgba(252, 186, 3, 1);

$default_font: Moderat, 'Helvetica Neue', Helvetica, Arial, sans-serif;

// New colors inroduced in Center Overview
$mint: #CCF3EB;
$rose: #EDD4ED;
$peach: #FFE5AD;
$moon: #D9DDF8;
$champagne: #FADFCC;