@use '//src/styles/scss/wonderschool/variables';

.add-charge-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.billing-interval {
  color: #0e6eb8;
  font-weight: bolder;
}
.billing-table-header {
  background-color: variables.$almostWhite;
  font-weight: bold;
  padding: 10px 5px;
}
.billing-table-row td {
  vertical-align: middle;
  cursor: pointer;
}
.billing-table-row:hover .invoice-edit-link {
  display: inline;
}

/* details widget */
.details-widget.ui.card > .details-widget-header.content {
  background-color: #f2f2f2;
  font-size: 13px;
}
.details-widget .ui.list > .item .header,
.details-widget .ui.list > .item .description {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details-widget .ui.list > .item .description {
  color: #969ba5;
}
.discount-amount {
  color: #db2828;
  font-size: 18px;
  font-weight: bold;
}
.discount-delete-button {
  text-align: right;
}
.discount-delete-button .ui.button {
  margin-bottom: 1px;
}
.discount-row {
  align-items: center;
  background-color: #eff1f5;
  padding: 20px 0;
}

.grid-header {
  padding: 0 7px;
}
.grid-header > * {
  background-color: variables.$almostWhite;
  font-weight: bold;
  padding: 10px 5px;
}

.invoice-edit-link {
  display: none;
}
.invoice-total .inner {
  text-align: right;
  font-size: 18px;
  padding-right: 20px !important;
}
.invoice-total strong {
  font-size: 20px;
  color: #000;
}

.label-after {
  margin-left: 5px !important;
}
label.plan-wrapper {
  position: relative;
}
label.plan-wrapper .icon {
  position: absolute;
  bottom: 0;
}
.label-required::after {
  color: #db2828;
  content: '*';
  font-weight: bold;
  margin-left: 3px;
}

.notifications-days {
  max-width: 60px;
}

.plan-info {
  margin-left: 8px !important;
}

.sending-frequency-dropdown-wrapper .ui.fluid.dropdown {
  display: inline-block;
}

/* Steps */
.steps-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ui.grid > .row > .column.step-wrapper {
  padding: 0;
}
.ui.grid.steps-wrapper {
  margin: 0;
}
.step-wrapper {
  text-align: center;
  font-weight: bold;
}
.step-wrapper.enabled .step-title,
.step-wrapper.disabled .step-title {
  color: #969ba5;
}
.step-title {
  text-align: center;
}

.step .students-summary-list .icon {
  padding-top: 6px;
}
/* Force override semantic ui styles */
ui.basic.label.label-required {
  border: none;
}
.ui.button.steps-button {
  margin: 5px 0 5px 0;
}
.ui.card > .content.students-summary-card-header {
  background-color: #f2f2f2;
  font-size: 13px;
}
.ui.form .fields.discount-edit-fields {
  align-items: flex-end;
}
