@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/moderat/moderat-light.woff2') format('woff2'),
    url('../../fonts/moderat/moderat-light.woff') format('woff');
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/moderat/moderat-regular.woff2') format('woff2'),
    url('../../fonts/moderat/moderat-regular.woff') format('woff');
}

@font-face {
  font-family: 'Moderat';
  font-style: italic;
  font-weight: 400;
  src: url('../../fonts/moderat/moderat-regular-italic.woff2') format('woff2'),
    url('../../fonts/moderat/moderat-regular-italic.woff') format('woff');
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/moderat/moderat-bold.woff2') format('woff2'),
    url('../../fonts/moderat/moderat-bold.woff') format('woff');
}

@font-face {
  font-family: 'Moderat';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/moderat/moderat-bold.woff2') format('woff2'),
    url('../../fonts/moderat/moderat-bold.woff') format('woff');
}
