.overflowFix {
    max-width: 100%;
    overflow-x:clip;
    overflow-y:visible;
}

.overflowFix :global(.divider.text) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:calc(100% - 1em);
}

.overflowFix :global(.dropdown .item) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width:calc(100%);
}
