@use '//src/styles/scss/wonderschool/variables';

.transactionsListHeader {
  background-color: variables.$almostWhite;
  font-weight: bold;
  padding: 10px 5px;
}

.transactionViewCell {
  width: '100px';
}
.transactionViewLink {
  display: none;
}
.transactionsListRow:hover .transactionViewLink {
  display: inline;
}

.transactionsListRow td {
  vertical-align: middle;
  cursor: pointer;
}
.transactionAmount {
  text-align: center;
}
.transactionsSearchBar :global(.ui.grid > .row > .column.noPadding) {
  padding-left: 0px;
  padding-right: 0px;
}
.transactionsSearchBar :global(.ui.grid > .row > .column.noPaddingLeft) {
  padding-left: 0px;
}
.transactionsSearchBar :global(.ui.grid > .row > .column.searchButton) {
  padding-left: 0px;
}
