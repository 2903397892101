@use '//src/styles/scss/wonderschool/variables';

.featureFlagsLink {
  background-color: variables.$bgHighlight;
  color: variables.$dark-gray;
  padding: 0.25rem 1rem;
  border-radius: 8px;
}
.featureFlagsLink:hover {
  background-color: variables.$bgHighlightHover;
  color: variables.$darkest-gray;
}
