.organizations {
  background: $secondary;

  .top-nav {
    margin-bottom: 0px !important;
  }

  .banner {
    // background: #ef434a !important;
    background: $white !important;
    margin-top: 0px !important;
    padding-top: 2em;
    border-bottom: 1px solid #f1f1f1 solid;
    @include box-shadow(2px, 0, 2px, rgba(0, 0, 0, 0.1));

    .top {
      padding-top: 2em;
      padding-bottom: 2.5em;
    }

    h2 {
      .sub.header {
        padding-top: 10px;
        font-family: $default_font !important;
      }
    }

    .main-stats {
      // background: $white;
      // border-radius: 5px;
    }
  }
}
