
.studentFilterRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.studentAgeDropdown {
  min-width: 8em !important;
  width: 8em;
}

.studentTableEditCell {
  text-align: right;
}

.studentDataPlaceholder {
  /* value taken from /src/styles/scss/wonderschool/_variables.scss : $blue */
  color: rgb(36, 111, 169);

  display: inline-block;
  text-align: left;
  padding-left: 3px;

  transform: scale(3,.5);
  -webkit-transform: scale(3,.5);
  -moz-transform: scale(3,.5);
  -ms-transform: scale(3,.5);
  -o-transform: scale(3,.5);
}

.studentContactCellDiv {
  min-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.studentAddedBannerWrap {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.studentPageTitleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.parentAvatarDimensions {
  max-width: 50px;
  padding-top:0.5rem;
}
.invoicesListRow td {
  vertical-align: middle;
}