.dashboard {
  .pushable {
    & > .pusher {
      &.mobile-container {
        #content {
          margin-top: 1em;

          .banner {
            background: $white !important;
            border-bottom: none;
            box-shadow: none;
          }
        }
      }
    }
  }

  .setup-ribbon {
    background: $white !important;
    margin-bottom: 0 !important;
  }

  .banner {
    background: $white !important;
    margin-top: 0;
  }
}
