.subtitle {
  display: flex;
  font-weight: normal;
  font-size: 1rem;
  margin-top: 0.5rem;
}

.avatar {
  display: inline-flex;
  margin-right: 0.5rem;
}


.listContent {
  display: inline-flex;
}