@use '//src/styles/scss/wonderschool/variables';

.carousel {
  width: calc(100% + 2rem);
  margin: -1rem;
}

.carouselNav,
.carouselNavIcon {
  padding: 0 !important;
  margin: 0 !important;
}

.carouselNavButton {
  padding: 0;
  margin: 0;
  border: 0;
  background: 0;
}

.carouselSlider {
  padding: 0 0.5rem;
}

.carouselItem {
  padding: 1rem 0.5rem;
  height: 100%;
}

.room {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.roomCount {
  font-size: 3rem !important;
  font-weight: bold !important;
  letter-spacing: -1px;
  margin-top: 0 !important;
  margin-bottom: 1rem !important;
  text-align: center;
}

.roomHeading {
  min-height: 68px;
}

.roomTitle {
  margin-bottom: 0 !important;
  line-height: 24px !important;
}

.roomSubtitle {
  line-height: 20px;
}

.roomAvatars {
  display: flex;
}

.roomAvatar + .roomAvatar {
  margin-left: 0.25rem;
}

.clickable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.mint {
  background-color: variables.$mint !important;
}

.rose {
  background-color: variables.$rose !important;
}

.peach {
  background-color: variables.$peach !important;
}

.moon {
  background-color: variables.$moon !important;
}

.champagne {
  background-color: variables.$champagne !important;
}