.room-age-input-split {
  display: flex;
  gap: 10px;
  
  .age-input {
    margin: 0 !important;
    
    > .ui.selection.dropdown {
      min-width: 8em;
    }
  }
}