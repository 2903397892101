.confirm-payment.ui.card > .confirm-payment-header.content {
  background-color: #f2f2f2;
}

.confirm-payment .ui.list > .item .header,
.confirm-payment .ui.list > .item .description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 20px;
}

.confirm-payment .ui.list > .item .description {
  font-weight: bold;
  font-size: 18px;
}

.confirm-payment .ui.list > .item .header.company-header {
  font-size: 30px;
}

.already-paid-checkbox {
  padding: 10px 0;
}
