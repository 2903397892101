.emailInput {
  position: relative;
}

.emailInput :global(.disabled.field) {
  opacity: 1 !important;
}
.emailInput :global(.disabled.field) > label {
  opacity: 1 !important;
}

.editLink {
  position: absolute;
  top: 3px;
  right: 0;
  font-size: 12px;
}
