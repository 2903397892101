.employees-list.ui.selection.list > .item {
  padding: 20px;
}

.ui.cards>.student.card.disabled {
  background-color: #f2f2f2;
  box-shadow: none;
}

a.ui.student.card.disabled:hover {
  cursor: initial;
  background-color: #f2f2f2;
  transform: none;
}
