.aging-total-summary-label {
  min-height: 72px;
}

.aging-total-summary-description {
  white-space: nowrap;
}

.aging-total-summary-amount {
  font-size: 1.5em;
  font-weight: normal;
  letter-spacing: -1px;
  margin-top: 0;
}

@media (min-width: 992px) {
  .aging-total-summary-amount {
    font-size: 2em;
  }
}
