.ui.table.billing-summary {
}

.ui.table.billing-summary tr th {
  vertical-align: middle;
}

.ui.table.billing-summary tr td {
  border-top-width: 0px;
  vertical-align: middle;
}

.fastpay-status-size {
  font-size: 16px;
}

.fastpay-status-font-weight {
  font-weight: normal;
}
