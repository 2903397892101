.billing {
  background: #fff;

  // @media screen and (min-width: 768px) {
  //   .pushable {
  //     & > .pusher {
  //       #content {
  //         margin: 6em auto 0;
  //         width: 80%;
  //       }
  //     }
  //   }
  // }
}
