@use '//src/styles/scss/wonderschool/variables';

.bstRow td {
  vertical-align: middle;
}

.header {
  background-color: variables.$almostWhite;
  font-weight: bold;
  padding: 10px 5px;
}

.blueCell {
  color: #0e6eb8;
  font-weight: bolder;
}
