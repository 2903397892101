.sendInvitationButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.signUpPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.signUpFormWrapper {
  flex: 1;
  max-width: 600px;
}
.networkLogoWrapper {
  flex: 1;
  padding-top: 100px;
  max-width: 600px;
}
.networkLogoTitle {
  text-align: center;
}
.form {
  width: 600px !important;
}
tr.networkRow {
  cursor: pointer;
}
