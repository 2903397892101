.student {
  border: 2px #d3d3d3 solid;
  box-shadow: none;

  //   &:hover {
  //     border: 2px rgb(133, 133, 133) solid !important;
  //     box-shadow: none !important;
  //   }

  &:hover.selected,
  &.selected {
    border: 2px #21ba45 solid !important;
  }
}
